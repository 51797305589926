<template>
  <div class="ft-roles">
    <b-table-filter
      :items="rows"
      :fields="fields"
      :filter="filter"
      :filter-on="filterOn"
      :is-busy="false"
      @to-edit="toEdit"
      title="Danh Sách Quyền "
    >
      <template #rightAction>
        <b-button :to="{name: 'roles-create'}" variant="success" size="sm">
        <feather-icon icon="PlusIcon" /> Thêm Quyền</b-button>
      </template>
    </b-table-filter>
  </div>
</template>

<script>
import {
  BFormInput, BCol, BRow, BFormGroup,
  BFormDatepicker, BButton, BFormCheckbox, BTabs, BTab,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import VueSelect from 'vue-select'
import BTableFilter from '@core/components/datatable/BTableFilter.vue'
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'

export default {
  methods: {
    toEdit(data) {
      this.$router.push({name: 'roles-edit', params: { id: data.item.id}})
    }
  },
  components: {
    BFormCheckbox,
    VueSelect,
    BFormGroup,
    BCol,
    BRow,
    BTableFilter,
    BFormDatepicker,
    BFormInput,
    BButton,
    BTabs,
    BTab,
    FeatherIcon
},
  directives: {
    Ripple,
  },
  data() {
    return {
      pageLength: 10,
      dir: false,
      fields: [
        {
          label: 'Tên',
          key: 'role_name',
        },
        {
          label: 'Nhóm Quyền',
          key: 'role',
        },
        {
          label: 'Trạng Thái',
          key: 'status',
        },
        {
          label: 'Tác vụ',
          disableBtnDelete: true,
          key: 'act',
        },
      ],
      rows: [],
    }
  },
  created() {
    this.rows = [
      {
        id: 1,
        role_name: 'MKT',
        role: 'MKT',
        status: [1, 2, 3,4,5],
      },
    ]
  },
}
</script>
